import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD3AWY3Q23PrDgm_DeJSh5BF_1kdYJFFbI",
  authDomain: "kahaniexpress-743ad.firebaseapp.com",
  projectId: "kahaniexpress-743ad",
  storageBucket: "kahaniexpress-743ad.appspot.com",
  messagingSenderId: "1061575376016",
  appId: "1:1061575376016:web:215819120b6afbe11f2c15",
  measurementId: "G-2KT6LJ3Q1R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);
const db = getFirestore(app);

// Enable offline persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.log('Persistence failed - multiple tabs open');
    } else if (err.code === 'unimplemented') {
      console.log('Persistence is not available in this browser');
    }
  });



export { auth, db };

