import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import messageReducer from './features/messageSlice';
import storyReducer from './features/storyReducer'; // Import the story reducer
import userReducer from './features/userSlice';
const persistConfig = {
  key: 'root',
  storage,
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,  // Persisted user reducer
    story: storyReducer,       // Story reducer
    messages: messageReducer,  // Message reducer       
  },
});

export const persistor = persistStore(store);
export default store;
