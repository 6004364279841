import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { config } from '../Common/Constants';
// Initial state for the story slice
const initialState = {
  storyName: '',
  storyContent: '',
  author: '',
  status: 'idle',  // Tracks the status of the API request
  error: null,     // Stores error messages if submission fails
};
const URL = config.url;

// Create an async thunk to handle story submission
export const submitStory = createAsyncThunk(
  'story/submitStory',
  async (storyRequest, { rejectWithValue }) => {
    try {
      const New_URL = `${URL}/storiesRequest`;
      const response = await fetch(New_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ storyRequest }),
      });

      if (!response.ok) {
        const errorData = await response.json();  // Extract the error message from the response
        return rejectWithValue(errorData.message);  // Pass the server's error message
      }

      const data = await response.json();
      return data;  // This will be the payload sent to the reducer in case of success
    } catch (error) {
      return rejectWithValue(error.message);  // If there's an error, reject with the error message
    }
  }
);

const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;  // Dynamically update the form fields
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitStory.pending, (state) => {
        state.status = 'loading';  // API call is in progress
        state.error = null;        // Reset any previous errors
      })
      .addCase(submitStory.fulfilled, (state) => {
        state.status = 'succeeded';  // API call was successful
        // Reset the form fields, but DO NOT return initialState
        state.storyName = '';
        state.storyContent = '';
        state.author = '';
      })
      .addCase(submitStory.rejected, (state, action) => {
        state.status = 'failed';     // API call failed
        state.error = action.payload;  // Set the error message from the rejected thunk
      });
  },
});

export const { updateForm } = storySlice.actions;
export default storySlice.reducer;
