import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { signOut } from 'firebase/auth';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../features/userSlice';
import { auth } from '../firebase';
import './UserInfo.css';

const UserInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user); // Get the user object from Redux store

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logoutUser()); // Clear the user data from the Redux store
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <Box className="user-info">
      {user ? (
        <>
          <span>Welcome, {user.displayName || 'User'}</span> {/* Display the user's name */}
          <Button onClick={handleLogout}>Logout</Button>
        </>
      ) : (
        <Button variant="contained" color="primary" onClick={() => navigate('/login')}>
          Login
        </Button>
      )}
    </Box>
  );
};

export default UserInfo;
